@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "../../assets/scss/helper";
.goods-image {
  margin-left: -15px;
  display: flex;
  & img {
    width: 100%;
    height: 100%;
    height: 80px;
    max-width: 120px;
    object-fit: cover;
    padding: 5px;
  }
  & span {
    font-size: small;

    @media #{$small-mobile} {
      font-size: 10px;
    }
  }

  @media #{$small-mobile} {
    font-size: 10px;
    & img {
      padding: 0px;
    }
    display: block;
  }
}
.shop {
  & h5 {
    font-size: 70%;
  }
}
.checkout {
  font-size: 15px;
  margin-bottom: 5px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #1261B0;
}
.deleteB {
  position: absolute;
  top: -25px;
  right: 0px;
  border: none;
  border-radius: 50%;
  background: white;
  font-size: 20px;
  color: black;
  font-weight: bold;
}
