.signB {
  margin-top: 20px;
  border-radius: 4px;
  /* justify-content: center; */
  /* overflow: none; */
  position: relative;
  border: 0;
  background-color: #1261B0;
  font-size: 14px;
  color: white;
  font-weight: 500;
  width: 100%;
}
