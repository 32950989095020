.back_B {
  color: white;
  padding: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: none;
  position: relative;
  bottom: 10px;
  background-color: #1261B0;
  border-radius: 5px;
}
