/*----------------------------------------*/
/*  09. Comment CSS
/*----------------------------------------*/

/*-- Comment Wrap --*/
.comment-wrap {
  margin-top: 66px;
  float: left;
  width: 100%;

  & h3 {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  // Responsive
  @media #{$large-mobile} {
    margin-top: 48px;
    & h3 {
      margin-bottom: 30px;
      font-size: 18px;
    }
  }
}

/*-- Comment List --*/
.comment-list {
  float: left;
  width: 100%;
  margin-bottom: 40px;

  // Responsive
  @media #{$large-mobile} {
    margin-bottom: 17px;
  }
}

/*-- Child Comment --*/
.child-comment {
  margin-left: 100px;

  // Responsive
  @media #{$small-mobile} {
    margin-left: 40px;
  }
}

/*-- Single Comment --*/
.single-comment {
  float: left;
  width: 100%;
  max-width: 670px;
  margin-bottom: 30px;

  /*-- Image --*/
  & .image {
    float: left;
    width: 100px;
    margin-right: 30px;
    border-radius: 5px;
    overflow: hidden;
  }

  /*-- Content --*/
  & .content {
    & h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
    }

    & span {
      display: block;
      color: $heading-color;
      font-size: 13px;
      line-height: 1;
      font-family: $heading-font;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    & p {
    }
  }

  /*-- Reply --*/
  & .reply {
    width: 100%;
    float: left;
    display: flex;
    clear: both;
    overflow: hidden;
    justify-content: left;

    & a {
      font-size: 14px;
      font-family: $heading-font;
      line-height: 18px;
      color: $heading-color;

      & i {
        margin-right: 10px;
      }

      &:hover {
        color: $heading-color;
      }
    }
  }

  // Responsive
  @media #{$small-mobile} {
    & .image {
      width: 70px;
      margin-right: 15px;
    }
  }
}

/*-- Comment Form --*/
.comment-form {
  & input {
    font-family: $heading-font;
    display: block;
    width: 100%;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #dbdbdb;
    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 12px;
    color: $body-color;
    margin-bottom: 0;

    &:focus {
      border-color: $body-color;
    }
  }

  & textarea {
    font-family: $heading-font;
    display: block;
    width: 100%;
    height: 80px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #dbdbdb;
    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 12px;
    color: $body-color;
    resize: none;
    margin-bottom: 0;

    &:focus {
      border-color: $body-color;
    }
  }

  button {
    border: none;
    font-weight: 500;
    text-transform: uppercase;
    width: auto;
    background-color: $heading-color;
    padding: 10px 35px;
    color: $white;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0;

    &:hover {
      background-color: $heading-color;
    }
  }
}
