@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "../../assets/scss/helper";
.picture1 {
  object-fit: cover;
  padding: 20px;
  padding-top: 0;
  margin: 0;

  //padding: 0 30px;
  //right: 0;
}

.picture2 {
  padding: 20px;
  padding-top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  // max-height: 400px;
  max-width: 200px;
}

.picture3 {
  padding: 20px;
  padding-top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  // max-height: 400px;
  max-width: 200px;
}

.res {
  display: flex;

  @media #{$desktop-device} {
    font-size: normal;
  }
  @media #{$tablet-device} {
    display: block;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  @media #{$small-mobile} {
    display: block;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
}
