/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/

/*-- Hero Image --*/
.hero-image {
  & img {
    width: 100%;
  }
}

/*-- Blog home Hero --*/
.blog-home-hero {
  height: 100vh;
  width: 100%;
  background-image: url(../images/bg/blog-hero.png);
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;

  // Responsive
  @media #{$tablet-device} {
    height: auto;
    padding: 150px 0;
  }

  @media #{$large-mobile} {
    height: auto;
  }

  @media #{$small-mobile} {
  }
}

/*-- Blog home Content --*/
.blog-home-hero-content {
  padding: 50px 15px;

  & h1 {
    font-size: 72px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    color: $heading-color;
    text-transform: uppercase;
    margin-bottom: 23px;
  }

  & p {
    max-width: 720px;
    font-size: 22px;
    line-height: 34px;
    font-family: $heading-font;
    color: $heading-color;
    letter-spacing: 1px;
    margin: auto;
  }

  // Responsive
  @media #{$desktop-device} {
    & h1 {
      font-size: 50px;
    }

    & p {
      font-size: 18px;
      line-height: 30px;
      max-width: 550px;
    }
  }

  @media #{$tablet-device} {
    & h1 {
      font-size: 50px;
    }

    & p {
      font-size: 18px;
      line-height: 30px;
      max-width: 550px;
    }
  }

  @media #{$large-mobile} {
    & h1 {
      font-size: 30px;
    }

    & p {
      font-size: 14px;
      line-height: 26px;
    }
  }

  @media #{$small-mobile} {
    & h1 {
      font-size: 24px;
    }
  }
}

/*----- new hero style start -----*/
.slider-bg-1 {
  background-image: url(../images/hero/slider-1.jpg);
}

/*Main Slider One*/
.main-slider-1 {
  height: 800px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-color: gray;

  // Responsive
  @media #{$laptop-device } {
    height: 600px;
  }

  @media #{$desktop-device} {
    height: 550px;
  }

  @media #{$tablet-device} {
    height: 400px;
  }

  @media #{$large-mobile} {
    height: 300px;
  }
}

/*Main Slider One Content*/
.main-slider-content {
  background-color: white;
  opacity: 0.6;
  padding: 2% 0;

  & h2 {
    font-weight: normal;
    color: black;
    font-size: 80px;
    line-height: 1;
    font-weight: 300;
    text-align: center;
  }

  & h1 {
    font-size: 80px;
    line-height: 1;
    font-weight: 400;
  }

  & a {
    font-size: 26px;
    line-height: 1.05;
    color: $heading-color;
    border-bottom: 2px solid $heading-color;
    position: relative;
    margin-top: 40px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -2px;
      width: 0;
      height: 2px;
      transition: all 0.3s ease 0s;
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }

  // Responsive
  @media #{$tablet-device} {
    & h2 {
      font-size: 40px;
    }

    & h1 {
      font-size: 60px;
    }

    & a {
      font-size: 24px;
      margin-top: 25px;
    }
  }

  @media #{$large-mobile} {
    & h2 {
      font-size: 36px;
    }

    & h1 {
      font-size: 50px;
    }

    & a {
      font-size: 20px;
      margin-top: 20px;
    }
  }

  @media #{$small-mobile} {
    & h2 {
      font-size: 30px;
    }

    & h1 {
      font-size: 40px;
    }

    & a {
      font-size: 20px;
      margin-top: 15px;
    }
  }

  @media #{$extra-small-mobile} {
    & h2 {
      font-size: 24px;
    }

    & h1 {
      font-size: 32px;
    }

    & a {
      font-size: 18px;
      margin-top: 10px;
    }
  }
}

/*----- new hero style end -----*/

/*----- freelancer portfolio hero start -----*/
.banner-overlay {
  position: relative;

  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }
}

.slider-banner {
  &-info {
    background-color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    margin: auto;
    padding: 65px;
    outline: 1px solid #fff;
    outline-offset: 8px;
    position: relative;
    z-index: 1;

    // responsive
    @media #{$large-mobile} {
      padding: 40px;
    }

    @media #{$small-mobile} {
      padding: 20px;
    }
  }

  &-title {
    font-size: 36px;
    font-weight: 500;

    // responsive
    @media #{$small-mobile} {
      font-size: 24px;
    }

    b {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &-text {
    font-size: 20px;
    padding-top: 10px;

    // responsive
    @media #{$small-mobile} {
      font-size: 16px;
    }
  }
}

/*----- freelancer portfolio hero end -----*/
