@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "../../../assets/scss/helper";
/* --------addnew--------- */
.container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-form-wrap {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0px 2px 12px black;
  box-shadow: 0px 2px 12px black;
  margin: 20px;
}
.rld-single-input {
  margin-top: 15px;
}

.image {
  margin-bottom: 20px;
}

.image.show {
  margin-bottom: 20px;

  /*  @media #{$small-mobile} {
    margin-bottom: 380px;
  }
  @media #{$tablet-device} {
    margin-bottom: 300px;
  } */
  /* @media #{$large-mobile} {
    margin-bottom: 320px;
  } */
}
.rld-single-input input {
  height: 48px;
  line-height: 48px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #d4d4d4;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
  font-size: 15px;
}
.rld-single-input input:hover,
.rld-single-input input:active,
.rld-single-input input:focus {
  border: 1px solid #1261B0;
}

.rld-single-input textarea {
  height: 120px;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #d4d4d4;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
  font-size: 15px;
}
.rld-single-input textarea:hover,
.rld-single-input textarea:active,
.rld-single-input textarea:focus {
  border: 1px solid #1261B0;
}

.btn {
  margin-top: 20px;
  border-radius: 4px;
  /* justify-content: center; */
  /* overflow: none; */
  position: relative;
  border: 0;
  background-color: #1261B0;
  font-size: 14px;
  color: white;
  font-weight: 500;
  width: 100%;
}

.delete {
  // position: relative;

  font-size: 12px;
  margin-bottom: 5px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #1261B0;
}

.SAVE {
  justify-content: end;
  font-size: 15px;

  border: none;
  border-radius: 4px;
  color: white;
  background-color: #1261B0;
}
